import React, { useContext } from "react";
import { AppContext } from "../../store/Context";

export const DeleteButton = ({ id, name }) => {
  const { state, dispatch } = useContext(AppContext);

  const deleteItemFromCart = () => {
    let confirm = window.confirm(`Are you sure want to delete ${name} from cart?`);
    if (!confirm) {
      return;
    }
    const cart = state.cart
    const updatedCart = cart.filter((item) => item.id !== id);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    dispatch({
      type: "SET_CART",
      payload: updatedCart,
    });
    dispatch({
      type: "SET_CART_COUNT",
      payload: updatedCart?.length,
    });
  };
  return (
    <span className="deleteCartBtn" onClick={() => deleteItemFromCart()}>
      <TrashSvg />
    </span>
  );
};

const TrashSvg = () => {
  return (
    <svg version="1.1" id="trash" x="0px" y="0px" viewBox="0 0 227.7 287.7">
      <path
        d="M200,89.9c-28.7,0-57.4,0-86,0c-29.1,0-58.1,0-87.2,0c-9.2,0-14.2,5.2-13.3,14.4c2.3,25.1,4.7,50.1,7.1,75.2
   c2.4,24.9,5,49.8,7.1,74.8c1.6,18.9,15.8,33.1,34.8,33.2c34.2,0.2,68.3,0.2,102.5,0c19.2-0.1,33.4-14.2,35.1-33.4
   c2.1-24.5,4.6-48.9,6.9-73.3c2.4-25.3,4.9-50.6,7.2-75.9C215.1,94.7,210.4,89.9,200,89.9z M96.9,246.5c-0.5,6.2-5.6,10.9-11.6,11
   c-6.1,0.1-11.4-4.4-11.9-10.7c-1.1-14.4-2-28.8-2.9-43.2c-1.4-22.4-2.9-44.8-4.2-67.1c-0.1-2.2-0.4-4.5,0-6.7
   c0.9-5.9,6.4-10.2,12.3-9.9c6.2,0.3,10.9,5.2,11.4,11.7c1,14.7,1.9,29.3,2.8,44c1.4,21.8,2.7,43.5,4,65.3h0.2
   C96.9,242.8,97.1,244.7,96.9,246.5z M161.6,132.6c-0.8,15.7-1.9,31.3-2.9,47c-1.4,22.3-2.8,44.5-4.3,66.8
   c-0.5,6.8-6.1,11.6-12.5,11.2c-6.6-0.5-11.4-6.1-11.1-13.1c0.1-1.5,0.2-3,0.3-4.5H131c2.3-36.2,4.5-72.4,6.9-108.5
   c0.4-6.8,5.8-11.8,12.3-11.5C156.8,120.1,162,125.6,161.6,132.6z"
      />
      <path
        d="M213.6,36c-17.1,0-34.1,0-51.8,0c0-2.1,0-3.9,0-5.6c-0.2-17.2-13.1-30.2-30.4-30.3c-12-0.1-23.9-0.1-35.9,0
   C79.2,0.3,66.2,13.3,65.9,29.7c0,2,0,4.1,0,6.3c-18.2,0-35.8,0-53.3,0C5.1,36,0.1,41,0,48.4C0,53.7,0,58.9,0,64.1
   C0,73.3,4.7,78,13.8,78c17.7,0,35.4,0,53.1,0c48.9,0,97.8,0,146.6,0c9.6,0,14.1-4.5,14.1-14.2c0-4.6,0-9.2,0-13.8
   C227.6,40.6,223,36,213.6,36z M137.7,35.7H90c0-2-0.1-3.7,0-5.5c0.2-3.7,2.4-6.1,6.1-6.1c11.8-0.1,23.7-0.1,35.5,0
   c3.8,0,5.9,2.4,6.1,6.1C137.8,32,137.7,33.7,137.7,35.7z"
      />
    </svg>
  );
};
